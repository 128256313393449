export default (chunk) => `<div class="mainDiv container-fluid">
<div class="row h-100">
    <div class="d-none d-md-block col-md-6 bg-primary h-100vh text-white order-2 order-md-1 overflow-x-hidden">
        <div class="login-content-container pt-5 pb-5 login-content">
            <div class="position-relative map-bg">
                <div class="ellipse-1">
                    <img src="/images/Ellipse-1.png" alt="">
                </div>
               <img src="/images/login-bg.svg" alt="" class="mt-md-3 w-100 top-img">
                <div class="ellipse-2">
                    <img src="/images/Ellipse-2.png" alt="">
                </div>
            </div>
            <div class="login-text-sec px-3">
            <h1 class="text-white font-weight-bold mt-0 pt-2">
            Eliminate the hassle of using multiple business software
        </h1>
        <p class="mt-3">
            Use the power of one CRM platform with its suites of products for sales, marketing event
            management,
            membership organisations and more.
        </p>
        <a href="https://www.optimiser.com/free-crm" target="_blank" class="btn btn-warning px-4">
            Try For Free
        </a>
            </div>
            
        </div>
    </div>
    <div class="col-md-6 h-100vh order-1 order-md-2">
        <div class="login-content-container py-5">
        <img src="/images/logo.svg" alt="Optimiser" class="mt-md-4">
           ${chunk}
        </div>
    </div>
    <div id="main-loading"><img src="/images/loader.gif" /></div>
</div>
</div>`