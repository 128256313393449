import './assets/scss/style.scss'
import { PageTarget } from './js/utils/common';
import { RegisterSW } from './sw-register';
import './views/common/root'
import './views/no-auth'

const target = PageTarget();
console.log('Page Target -> ', target);

switch (target) {
    case 'Root':
        $('#optimiser').replaceWith(_views.ROOT_VIEW);
        import('./views').then(() => import(
            /* webpackChunkName: "optimiser-main" */
            /* webpackMode: "lazy" */
            './js/controllers/main'
        ))

        break;
    case 'ForgotPassword':
        $('#optimiser').replaceWith(_views.noAuth.FORGOT_PASSWORD)
        import(
            /* webpackChunkName: "optimiser-forgot-password" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/forgot-password'
        );
        break;
    case 'Login':
        $('#optimiser').replaceWith(_views.noAuth.LOGIN)
        import(
            /* webpackChunkName: "optimiser-login" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/login'
        );
        break;
    case 'CheckEmail':
        $('#optimiser').replaceWith(_views.noAuth.CHECK_EMAIL);
        import(
            /* webpackChunkName: "optimiser-check-email" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/check-email'
        );
        break;
    case 'SetPassword':
        $('#optimiser').replaceWith(_views.noAuth.SET_PASSWORD);
        import(
            /* webpackChunkName: "optimiser-set-password" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/set-password'
        );
        break;
    case 'DriveShare':
        import(
            /* webpackChunkName: "optimiser-drive-share" */
            /* webpackMode: "lazy" */
            './js/controllers/drive/external-drive-share'
        );
        $('#optimiser').replaceWith(_views.noAuth.EXTERNAL_DRIVE_SHARE);
        break;
    case 'Unsubscribe':
        // QPC-7016 FIX FOR MARKETING UNSUBSCRIBE LINK
        import(
            /* webpackChunkName: "unsubscribe" */
            /* webpackMode: "lazy" */
            './js/controllers/marketing/unsubscribe'
        );
        $('#optimiser').replaceWith(`<div class='unsubscribe-page'>
        </div>`);
        break;
}

RegisterSW();