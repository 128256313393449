import CHECK_EMAIL from './check-email';
import FORGOT_PASSWORD from './forgot-password';
import JoinLayout from './layout';
import LOGIN, { OtpForm } from './login';
import SET_PASSWORD from './set-password';
import EXTERNAL_DRIVE_SHARE from './external-drive-share';

const noAuth = {
    LOGIN: JoinLayout(LOGIN),
    SET_PASSWORD: JoinLayout(SET_PASSWORD),
    CHECK_EMAIL: JoinLayout(CHECK_EMAIL),
    FORGOT_PASSWORD: JoinLayout(FORGOT_PASSWORD),
    EXTERNAL_DRIVE_SHARE,
    OTPFORM: OtpForm,
};

window._views['noAuth'] = noAuth;