export default ` <header class="p-3 shadow">
<div class="row">
    <div class="col d-flex align-items-center">
        <div class="lead file-name"></div>
    </div>
</div>
</header>
<div class="content-body container-fluid">
<!-- Loader -->
<div class="sharing-loader">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<section class="row h-100 main-sections d-none" style= "min-height: calc(100vh - 70px);flex-direction: column;align-items: center;justify-content: space-between;">
    <div class="col-sm-12 col-md-6 d-flex justify-content-center text-center p-4 mx-auto style= "height: auto; position: relative;" >
        <div class="d-inline-block align-self-center ">
            <!-- File Details -->
            <div class="d-none file-details">
                <div class="general-content pt-5">
                    <h2 class="text-primary owner-name mb-0 "> </h2>
                    <h4 class="text-secondary owner-email"> </h4>
                    <p class="display-4 file-type"></p>
                </div>
                <div class="">
                    <div class="custom-message position-relative">

                    </div>

                    <p class="sharing-content mt-3"></p>
                    <div class="mt-4">
                        <button class="btn btn-lg btn-primary dwnld-btn" data-toggle="modal">Download</button>
                    </div>
                </div>
                <div class="alert alert-success mt-2 h5 download-success d-none">
                    The file is being downloaded
                </div>

            </div>
            <div class="error-sharing d-none p-5">

            </div>
        </div>

    </div>

    <div class="pt-4"> <div> <bold> Powered By </bold> <a href="https://www.optimiser.com"><img src="/images/logo.svg" /></a> </div>
    </div>
</section>
<div class="modal fade" id="report-mail" tabindex="-1" role="dialog" aria-labelledby="report-mailTitle"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body p-4">

                <div class="carousel slide" data-interval="false" style="width: 100%!important;">

                    <!-- The slideshow -->
                    <div class="carousel-inner">
                        <div class="carousel-item pl-1 pr-1 active email-container">
                            <div class="row ">

                                <div class="col-12">
                                    <h2 class="text-primary">OTP Verification </h2>
                                    <p class="mt-3 folderShared h5"></p>
                                </div>
                                <div class="form-group col-12 mt-3">
                                    <label>Enter Email Address</label>
                                    <input class="form-control external-email" />
                                    <span class="unauthorized-error text-danger"><span>
                                </div>

                                <div class="form-group col-12 mt-2">
                                    <button class="btn btn-lg btn-primary gen-otp" disabled>Generate
                                        OTP</button>
                                </div>

                            </div>
                        </div>
                        <div class="carousel-item pl-1 pr-1 otp-container">
                            <div class="row">

                                <div class="col-12">
                                    <h2 class="text-primary enterOTP">Enter One Time Password</h2>
                                </div>
                                <div class="form-group col-12 mt-3">
                                    <input class="form-control otp-field" placeholder="OTP" />
                                    <span class="invalid-otp text-danger"><span></span>
                                </div>
                                <div class="form-group col-12">
                                    <a href="" class="otp-again">Send OTP again</a>
                                    <div class="text-success mail-send-again"></div>
                                </div>

                                <div class="form-group col-12 mt-2">
                                    <button class="btn btn-lg btn-primary verify-btn" disabled>Verify</button>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>

            </div>

        </div>
    </div>
</div>
</div>`