
export default `
<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
    Please Check Your Email
</h2>
<p class="text-secondary pb-1">
    We've sent you a link to reset your password.
</p>
<p class="text-secondary pb-1">No email received? Please check your junk or spam folders or click <a
        href="javascript:void(0)" class="resendPassword">Resend</a> for another email.</p>
<p class="text-secondary pb-3">For
    further support please contact your Optimiser Administrator</p>

<div class="form-group pb-3">
    <button type="button" class="btn btn-primary btn-lg btn-block btnLogin">
        Return to Login
    </button>
</div>
<hr>
<div class="col-md-12 text-center mt-4"><span class="font-size-14 mr-2">Need help with Login?
                    </span><a href="https://www.optimiser.com/contact-us" target="_blank"
                        class="btn btn-outline-primary ml-2 w-40 px-4">Contact Us
                    </a></div>`