
export default `<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
Forgot Password
</h2>
<form>
<div class="pb-3">
<p class="text-secondary">
  To reset your password, enter your <span class="text-primary">Optimiser's</span> registered
  email address.
</p>
<p class="text-danger link-expired d-none">The link to reset your password has expired. Please try again.</p>
</div>
<div class="form-group">
  <label for="exampleInputEmail1">Username</label>
  <input type="email" placeholder="useremail@company.com" required="" class="form-control txtEmail">
  <p class="text-danger invalid-email d-none">Enter valid username.</p>
  <p class="text-danger login-error"></p>
</div>

<div class="form-group">
  <button type="button" class="btn btn-primary btn-lg btn-block btn-login resetPassword">
    Reset Password
    <span class="spinner-border spinner-border-sm d-none"></span>
  </button>
</div>
<hr>

<div class="col-md-12 text-center mt-4"><span class="font-size-14 mr-2">Need help with Login?
                    </span><a href="https://www.optimiser.com/contact-us" target="_blank"
                        class="btn btn-outline-primary ml-2 w-40 px-4">Contact Us
                    </a></div>
</form>

`