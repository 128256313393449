
export default `
<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
    Login to your account
</h2>
<form>

    <div class="form-group">
        <label for="exampleInputEmail1">Username</label>
        <input type="email" placeholder="useremail@company.com" required="" class="form-control txtEmail">
    </div>

    <div class="form-group">
        <label for="exampleInputEmail1">Password</label>
        <div class="input-group">
            <input type="password" id="password-field" required class="form-control txtPassword">
            <div class="input-group-append">
                <span toggle="#password-field" class="input-group-text fa fas fa-eye eye-icon toggle-password"></span>
            </div>
            
        </div>
        <div class="pt-2 d-none NotValid text-danger leto" style="font-size:13px;">
                    <i class='fa fa-exclamation-circle danger' style='font-size:13px;'></i>&nbsp<span
                      class="printError"></span>
                  </div>

    </div>
    <div class="form-group">
        <div class="d-flex">
            <div class="custom-control custom-checkbox col-6"><input type="checkbox" id="customCheck"
                    class="custom-control-input"><label for="customCheck"
                    class="custom-control-label font-size-13">Remember me
                </label></div>
            <div class="col-6 text-right p-0"><a href="?page=ForgotPassword" class="font-size-13">Forgot your password?
                </a></div>
        </div>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-primary btn-lg btn-block btn-login btnLogin">
            Log In
        </button>
    </div>
    <div class="small leto" style="color: #6c757d;">
        By logging in, you confirm that you agree to the <a href="/Optimiser-Master-Subscription-Agreement.pdf"
            target="_blank">Master Subscription
            Agreement</a> .
    </div>
    <hr>

    <div class="col-md-12 text-center mt-4">
        <span class="font-size-14 mr-2">Not a Customer?</span>
        <a href="https://www.optimiser.com/free-crm" target="_blank" class="btn btn-outline-primary ml-2 w-40 px-4">Try For Free</a>
    </div>
</form>`

const OtpForm = function (email) {
    return `<button type="button" class="otpPopupBtn d-none" data-toggle="modal" data-target="#otpPopup">
    </button>
    <div class="modal fade otpScreen" data-backdrop="static" id="otpPopup" tabindex="-1" role="dialog" aria-labelledby="otpPopupLabel"  aria-hidden="true">
        <div class="modal-dialog modalDialog" role="document">
        <div class="modal-content modal-md">
          <div class="modal-body p-4">
          <div class="h2 font-weight-bold text-primary">Confirm Your One-Time Password</div>
          <div class="h5 mb-4 emailSent">A One-Time Password has been sent to - ${email} </div>
          <input class="submittedOtp form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="6" placeholder="Enter OTP" type="number" name="otp"> 
          <p class="message text-danger mt-2"> </p>
          <div class="h5 mb-4">Didn't receive the OTP? Click <a href="#" class="text-primary font-weight-bold resentOtp">here</a> to resend.</div>
          <div class="mb-2">
            <button type="button" class="btnSubmitOtp btn btn-primary btn-lg">Submit OTP</button>
            <button type="button" class="btnClose btn btn-secondary btn-lg ml-2" data-dismiss="modal">Cancel</button>
          </div>
          </div>
        </div>
      </div>
    </div>`
}

export {
    OtpForm
}