
export const HasSession = () => {
    return document.cookie.indexOf('msp_d=') !== -1;
}

export const PageTarget = () => {
    let params = new URLSearchParams(window.location.search);
    let page = params.get('page');

    if (!page && !HasSession()) return "Login";
    if (!page && HasSession()) return 'Root';
    return page;
}